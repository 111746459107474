import React from 'react'
import { Section, SectionTitle, MarkdownContent } from "../../components/Section"
import RelatedProductCarousel from "../../components/RelatedProductCarousel"

class TopSellingProducts extends React.Component {
	render(){
		const { data, products, location } = this.props
		return(
			<Section pt="119px" pb="141px" bg="#F4FBFF">
				<SectionTitle>{data.title}</SectionTitle>
				<p><MarkdownContent textAlign="center" dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} /></p>
				<div className="container">
					<RelatedProductCarousel data={products} location={location} />
				</div>
			</Section>
		)
	}
}

export default TopSellingProducts
import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import CheckIcon from "../Icons/CheckIcon"
import Step1 from "./first-step"
import Step2 from "./second-step"
import Step3 from "./third-step"
import Step4 from "./forth-step"
import styled from "styled-components"
import { StepWrap, ApplicationType, JointApp, SingleApp, Icon } from "./style"
import { Button } from "../FormStyle"

const Wrapper = styled.div`
  padding-bottom: 50px;
  @media (max-width: 1200px) {
    padding-bottom: 100px;
  }
`
const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 53;
  margin: auto;
  margin-top: 50px;
  text-align: ${props => props.textAlign};
  width: 100%;
  @media (max-width: 1200px) {
    text-align: center;
    max-width: 100%;
  }
`
ButtonWrapper.defaultProps = {
  mt: "50px",
  mBottom: "80px",
  textAlign: "center",
}

class FinancingForm extends React.Component {
  constructor(props) {
    super(props)
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      applicationTyp: "Single Application",
      dateType: "text",
      first_name: "",
      last_name: "",
      birth: "",
      email: "",
      ssn: "",
      phone_no: "",
      mobile_no: "",
      fNameRequire: false,
      fNameInputPattern: "",
      lNameRequire: false,
      lNameInputPattern: "",
      birthRequire: false,
      emailRequire: false,
      emailInputPattern: "",
      ssnRequire: false,
      ssnInputPattern: "",
      phoneRequire: false,
      phoneInputPattern: "",
      mobileRequire: false,
      mobileInputPattern: "",
      street_address: "",
      city: "",
      state: "",
      zipcode: "",
      own_rent: "",
      monthlyPayment: "",
      sAddressRequire: false,
      sAddressInputPattern: "",
      cityRequire: false,
      cityInputPattern: "",
      stateRequire: false,
      stateInputPattern: "",
      zipcodeRequire: false,
      zipCodeInputPattern: "",
      ownRentRequire: false,
      monthlyPaymentRequire: false,
      monthlyPaymentInputPattern: "",
      employer: "",
      job_title: "",
      employer_phone: "",
      salary: "",
      employment_length: "",
      employerRequire: false,
      employerInputPattern: "",
      jobTitleRequire: false,
      jobTitleInputPattern: "",
      employerPhoneRequire: false,
      employerPhoneInputPattern: "",
      salaryRequire: false,
      salaryInputPattern: "",
      employmentLengthRequire: false,
      order_detail: "",
      orderDetailRequire: false,
      agreeViaSms: true,
    }
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this._next = this._next.bind(this)
    this._prev = this._prev.bind(this)
  }

  onFocus(event) {
    const { type } = event.target
    this.setState({
      dateType: "date",
    })
  }
  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value, pattern } = event.target

    if (name !== "agreeViaSms") {
      this.setState({
        [name]: value,
      })
    } else {
      this.setState({
        [name]: event.target.checked,
      })
    }

    const inputRegex = new RegExp(pattern);
    
    if (name === "first_name") {
      this.setState({
        fNameRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          fNameInputPattern: ""
        })
      } else {
        this.setState({
          fNameInputPattern: "Name should be in a-z A-Z"
        })
      }
    }
    if (name === "last_name") {
      this.setState({
        lNameRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          lNameInputPattern: ""
        })
      } else {
        this.setState({
          lNameInputPattern: "Name should be in a-z A-Z"
        })
      }
    }
    if (name === "birth") {
      this.setState({
        birthRequire: false,
      })
    }
    if (name === "ssn") {
      this.setState({
        ssnRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          ssnInputPattern: ""
        })
      } else {
        this.setState({
          ssnInputPattern: "SSN should be number"
        })
      }
    }
    if (name === "email") {
      this.setState({
        emailRequire: false,
      })
      if (inputRegex.test(value)) {
        console.log(true)
        this.setState({
          emailInputPattern: ""
        })
      } else {
        console.log(false)
        this.setState({
          emailInputPattern: "Invalid email format"
        })
      }
    }
    if (name === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          phoneInputPattern: ""
        })
      } else {
        this.setState({
          phoneInputPattern: "Should accept only 10 digit and not greater than that."
        })
      }
    }
    if (name === "mobile_no") {
      this.setState({
        mobileRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          mobileInputPattern: ""
        })
      } else {
        this.setState({
          mobileInputPattern: "Should accept only 10 digit and not greater than that."
        })
      }
    }
    if (name === "street_address") {
      this.setState({
        sAddressRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          sAddressInputPattern: ""
        })
      } else {
        this.setState({
          sAddressInputPattern: "Please input valid address"
        })
      }
    }
    if (name === "city") {
      this.setState({
        cityRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          cityInputPattern: ""
        })
      } else {
        this.setState({
          cityInputPattern: "Please input valid city Name"
        })
      }
    }
    if (name === "state") {
      this.setState({
        stateRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          stateInputPattern: ""
        })
      } else {
        this.setState({
          stateInputPattern: "Please input valid State Name"
        })
      }
    }
    if (name === "zipcode") {
      this.setState({
        zipcodeRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          zipCodeInputPattern: ""
        })
      } else {
        this.setState({
          zipCodeInputPattern: "Should accept only 5 numbers"
        })
      }
    }
    if (name === "own_rent") {
      this.setState({
        ownRentRequire: false,
      })
    }
    if (name === "monthlyPayment") {
      this.setState({
        monthlyPaymentRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          monthlyPaymentInputPattern: ""
        })
      } else {
        this.setState({
          monthlyPaymentInputPattern: "Should be number"
        })
      }
    }
    if (name === "employer") {
      this.setState({
        employerRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          employerInputPattern: ""
        })
      } else {
        this.setState({
          employerInputPattern: "Name should be text with a-z|A-Z"
        })
      }
    }
    if (name === "job_title") {
      this.setState({
        jobTitleRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          jobTitleInputPattern: ""
        })
      } else {
        this.setState({
          jobTitleInputPattern: "Please input valid text"
        })
      }
    }
    if (name === "employer_phone") {
      this.setState({
        employerPhoneRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          employerPhoneInputPattern: ""
        })
      } else {
        this.setState({
          employerPhoneInputPattern: "Should accept only 10 digit and not greater than that."
        })
      }
    }
    if (name === "salary") {
      this.setState({
        salaryRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          salaryInputPattern: ""
        })
      } else {
        this.setState({
          salaryInputPattern: "Shuld be number"
        })
      }
    }
    if (name === "employment_length") {
      this.setState({
        employmentLengthRequire: false,
      })
    }
    if (name === "order_detail") {
      this.setState({
        orderDetailRequire: false,
      })
    }
  }

  // Trigger an alert on form submission
  handleSubmit = event => {
    let currentStep = this.state.currentStep
    const { location } = this.props
    event.preventDefault()

    if (this.state.order_detail === "") {
      this.setState({
        orderDetailRequire: true,
      })
    } else {
      const comment =
        "Application Type: " +
        this.state.applicationTyp +
        "; Socail Security Number(SSN): " +
        this.state.ssn +
        "; Date of Birth: " +
        this.state.birth +
        "; Own or Rent: " +
        this.state.own_rent +
        "; Monthly Payment: " +
        this.state.monthlyPayment +
        "; Employer Name: " +
        this.state.employer +
        "; Job Title: " +
        this.state.job_title +
        "; Employer Phone: " +
        this.state.employer_phone +
        "; Salary: " +
        this.state.salary +
        "; Employment Length:" +
        this.state.employment_length +
        "; Order Details:" +
        this.state.order_detail
      const data = {
        source: location.origin,
        form: 'Financing Form',
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        mobile_no: this.state.mobile_no,
        address_line_1: this.state.street_address,
        city: this.state.city,
        state: this.state.state,
        zipcode: this.state.zipcode,
        comment: comment + ", SMS consent : " + this.state.agreeViaSms,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          currentStep = 4
          navigate("/thank-you-financing/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }

  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1 or 2, then add one on "next" button click
    if (currentStep === 1) {
      if (this.state.first_name === "") {
        this.setState({
          fNameRequire: true,
        })
      } else if (this.state.last_name === "") {
        this.setState({
          lNameRequire: true,
        })
      } else if (this.state.ssn === "") {
        this.setState({
          ssnRequire: true,
        })
      } else if (this.state.birth === "") {
        this.setState({
          birthRequire: true,
        })
      } else if (this.state.phone_no === "") {
        this.setState({
          phoneRequire: true,
        })
      } else if (this.state.mobile_no === "") {
        this.setState({
          mobileRequire: true,
        })
      } else if (this.state.email === "") {
        this.setState({
          emailRequire: true,
        })
      } else if ( this.state.fNameInputPattern !== "" || this.state.lNameInputPattern !== "" || this.state.ssnInputPattern !== "" || this.state.phoneInputPattern !== "" || this.state.mobileInputPattern !== "" || this.state.emailInputPattern !== "") {
        currentStep = 1
      } else {
        currentStep = 2
      }
    } else if (currentStep === 2) {
      if (this.state.street_address === "") {
        this.setState({
          sAddressRequire: true,
        })
      } else if (this.state.city === "") {
        this.setState({
          cityRequire: true,
        })
      } else if (this.state.state === "") {
        this.setState({
          stateRequire: true,
        })
      } else if (this.state.zipcode === "") {
        this.setState({
          zipcodeRequire: true,
        })
      } else if (this.state.own_rent === "") {
        this.setState({
          ownRentRequire: true,
        })
      } else if (this.state.monthlyPayment === "") {
        this.setState({
          monthlyPaymentRequire: true,
        })
      } else if ( this.state.sAddressInputPattern !== "" || this.state.stateInputPattern !== "" || this.state.cityInputPattern !== "" || this.state.zipCodeInputPattern !== "" || this.state.monthlyPaymentInputPattern !== "") {
        currentStep = 2
      } else {
        currentStep = 3
      }
    } else if (currentStep === 3) {
      if (this.state.employer === "") {
        this.setState({
          employerRequire: true,
        })
      } else if (this.state.job_title === "") {
        this.setState({
          jobTitleRequire: true,
        })
      } else if (this.state.employer_phone === "") {
        this.setState({
          employerPhoneRequire: true,
        })
      } else if (this.state.salary === "") {
        this.setState({
          salaryRequire: true,
        })
      } else if (this.state.employment_length === "") {
        this.setState({
          employmentLengthRequire: true,
        })
      } else if ( this.state.employerInputPattern !== "" || this.state.jobTitleInputPattern !== "" || this.state.employerPhoneInputPattern !== "" || this.state.salaryInputPattern !== "") {
        currentStep = 3
      }  else {
        currentStep = 4
      }
    }
    this.setState({
      currentStep: currentStep,
    })
  }

  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({
      currentStep: currentStep,
    })
  }
  get previousButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button
          onClick={this._prev}
          width="200px"
          className="btn btn-secondary"
          type="button"
        >
          <div>Previous</div>
        </Button>
      )
    } else {
      return (
        <Button
          onClick={this._next}
          width="300px"
          mWidth="250px"
          className="btn btn-secondary"
          type="button"
        >
          <div>Get Started</div>
        </Button>
      )
    }
    // ...else return nothing
    return null
  }

  get nextButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 3, then render the "next" button
    if (currentStep > 1 && currentStep < 4) {
      return (
        <Button width="200px" type="button" onClick={this._next}>
          <div>Next</div>
        </Button>
      )
    } else if (currentStep === 4) {
      return (
        <Button width="200px" type="button" onClick={this.handleSubmit}>
          <div>Submit</div>
        </Button>
      )
    }

    return null
  }
  render() {
    const { applicationTyp } = this.state
    return (
      <Wrapper>
        <form onSubmit={this.handleSubmit}>
          <StepWrap>
            <ApplicationType>
              <JointApp
                color={
                  applicationTyp === "Joint Application" ? "#ED602B" : "#676B79"
                }
                boxShadow={
                  applicationTyp === "Joint Application"
                    ? "0 12px 25px rgba(0, 0, 0, 0.1)"
                    : "none"
                }
              >
                <input
                  type="radio"
                  name="applicationTyp"
                  value="Joint Application"
                  onChange={this.handleChange}
                />{" "}
                Joint Application
                <Icon
                  display={
                    applicationTyp === "Joint Application"
                      ? "inline-block"
                      : "none"
                  }
                >
                  <CheckIcon fill="#ED602B" />
                </Icon>
              </JointApp>
              <SingleApp
                color={
                  applicationTyp === "Single Application"
                    ? "#ED602B"
                    : "#676B79"
                }
                boxShadow={
                  applicationTyp === "Single Application"
                    ? "0 12px 25px rgba(0, 0, 0, 0.1)"
                    : "none"
                }
              >
                <input
                  type="radio"
                  name="applicationTyp"
                  value="Single Application"
                  onChange={this.handleChange}
                />
                Single Application
                <Icon
                  display={
                    applicationTyp === "Single Application"
                      ? "inline-block"
                      : "none"
                  }
                >
                  <CheckIcon fill="#ED602B" />
                </Icon>
              </SingleApp>
            </ApplicationType>
            <h3>Tell us some Basic Details</h3>
            <Step1
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              onFocus={this.onFocus}
              dateType={this.state.dateType}
              fNameValue={this.state.first_name}
              fNameRequire={this.state.fNameRequire}
              fNameInputPattern={this.state.fNameInputPattern}
              lNameValue={this.state.last_name}
              lNameRequire={this.state.lNameRequire}
              lNameInputPattern={this.state.lNameInputPattern}
              birthRequire={this.state.birthRequire}
              birthInputValue={this.state.birth}
              ssnRequire={this.state.ssnRequire}
              ssnValue={this.state.ssn}
              ssnInputPattern={this.state.ssnInputPattern}
              emailRequire={this.state.emailRequire}
              emailValue={this.state.email}
              emailInputPattern={this.state.emailInputPattern}
              phoneRequire={this.state.phoneRequire}
              phoneValue={this.state.phone_no}
              phoneInputPattern={this.state.phoneInputPattern}
              mobileRequire={this.state.mobileRequire}
              mobileValue={this.state.mobile_no}
              mobileInputPattern={this.state.mobileInputPattern}
            />
            <Step2
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              sAddressRequire={this.state.sAddressRequire}
              sAddressInputPattern={this.state.sAddressInputPattern}
              sAddressValue={this.state.street_address}
              cityRequire={this.state.cityRequire}
              cityInputPattern={this.state.cityInputPattern}
              cityValue={this.state.city}
              stateRequire={this.state.stateRequire}
              stateInputPattern={this.state.stateInputPattern}
              stateValue={this.state.state}
              zipcodeRequire={this.state.zipcodeRequire}
              zipCodeInputPattern={this.state.zipCodeInputPattern}
              zipCodeValue={this.state.zipcode}
              ownRentRequire={this.state.ownRentRequire}
              ownRentValue={this.state.own_rent}
              monthlyPaymentRequire={this.state.monthlyPaymentRequire}
              monthlyPaymentInputPattern={this.state.monthlyPaymentInputPattern}
              monthlyPaymentValue={this.state.monthlyPayment}
            />
            <Step3
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              employerRequire={this.state.employerRequire}
              employerInputPattern={this.state.employerInputPattern}
              employerValue={this.state.employer}
              jobTitleRequire={this.state.jobTitleRequire}
              jobTitleInputPattern={this.state.jobTitleInputPattern}
              jobTitleValue={this.state.job_title}
              employerPhoneRequire={this.state.employerPhoneRequire}
              employerPhoneInputPattern={this.state.employerPhoneInputPattern}
              employerPhoneValue={this.state.employer_phone}
              salaryRequire={this.state.salaryRequire}
              salaryInputPattern={this.state.salaryInputPattern}
              salaryValue={this.state.salary}
              employmentLengthRequire={this.state.employmentLengthRequire}
              employmentLengthValue={this.state.employment_length}
            />
            <Step4
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              orderDetailRequire={this.state.orderDetailRequire}
            />
          </StepWrap>
          <ButtonWrapper>
            {this.previousButton}
            {this.nextButton}
          </ButtonWrapper>
        </form>
      </Wrapper>
    )
  }
}

export default FinancingForm

import React from "react"
import {
  Section,
  SectionTitle,
  MarkdownContent,
  LeftTriangle,
  RightTriangle,
} from "../../components/Section"
import FinancingForm from "../../components/FinancingForm"

const FinanceFormSection = ({ data, location }) => {
  return (
    <Section bg="#F4FBFF" pt="132px" pb="0" mbp="0" mplr="0">
      <div className="container" style={{ zIndex: 59 }}>
        <SectionTitle maxWidth="800px">{data.title}</SectionTitle>
        <p>
          <MarkdownContent
            textAlign="center"
            color="#010920"
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
        </p>
        <FinancingForm location={location} />
      </div>
      <LeftTriangle />
      <RightTriangle />
    </Section>
  )
}

export default FinanceFormSection

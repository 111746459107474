import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CallToAction from "../../components/CallToAction"
import {
  Section,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../../components/Section"
import DesignPlan from "../../components/DesignPlan"
import AccreditedBusiness from "../../components/AccreditedBusiness"
import scrollToRef from "../../components/scrollToRef"
import QuotePopup from "../../components/QuotePopup"

import FinancingHero from "../../sections/BuyingGuide/FinancingHero"
import HowToApply from "../../sections/BuyingGuide/HowToApply"
import FinanceAvailable from "../../sections/BuyingGuide/FinanceAvailable"
import FinanceFormSection from "../../sections/BuyingGuide/FinanceFormSection"
import TopSellingProducts from "../../sections/BuyingGuide/TopSellingProducts"

const FinancingProcessPage = ({ data, location }) => {
  const [isQuoteVisible, setQuoteVisible] = useState(false)
  const pageContent = data.allContentfulFinancingPage.edges[0].node
  const productData = data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )
  const applyRef = React.createRef()
  const applyNav = () => {
    scrollToRef(applyRef)
  }
  const handleModalClose = () => {
    setQuoteVisible(false)
  }
  const handleModalOpen = () => {
    setQuoteVisible(true)
  }
  return (
    <Layout>
      <SEO
        title={pageContent.metaTitle}
        description={pageContent.metaDescription.metaDescription}
      />
      <FinancingHero
        data={pageContent.heroSection}
        subTitle={pageContent.heroSubTitle}
        applyCallBack={applyNav}
      />
      <HowToApply data={pageContent.applySection} applyCallBack={applyNav} />
      <FinanceAvailable
        data={pageContent.availableOption}
        modalOpen={handleModalOpen}
      />
      <div ref={applyRef} />
      <FinanceFormSection data={pageContent.formSection} location={location} />
      <CallToAction data={pageContent.ctaSection} />
      <Section pt="104px" pb="114px">
        <div className="container">
          <LeftPannel>
            <MarkdownContent
              dangerouslySetInnerHTML={{
                __html: pageContent.contentSection.childMarkdownRemark.html,
              }}
            />
          </LeftPannel>
          <RightPannel>
            <DesignPlan />
            <AccreditedBusiness />
          </RightPannel>
        </div>
      </Section>
      <TopSellingProducts
        data={pageContent.cardSection}
        products={productData}
        location={location}
      />
      <QuotePopup
        isVisible={isQuoteVisible}
        onClose={handleModalClose}
        location={location}
        formName="Financing Get Quote Form"
      />
    </Layout>
  )
}

export default FinancingProcessPage

export const pageQuery = graphql`
  query FinancingProcessPageQuery {
    allContentfulFinancingPage(filter: { title: { eq: "Financing Process" } }) {
      edges {
        node {
          title
          metaTitle
          metaDescription {
            metaDescription
          }
          heroSection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            features {
              title
              description
              icon
            }
          }
          heroSubTitle
          applySection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            features {
              content
            }
          }
          availableOption {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            features {
              image
              description
              options
            }
          }
          formSection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          ctaSection {
            title
            description {
              description
            }
          }
          contentSection {
            childMarkdownRemark {
              html
            }
          }
          cardSection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    allContentfulProduct(sort: { fields: numberOfSale }) {
      edges {
        node {
          id
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`

import React from "react"
import styled from "styled-components"
import { FormFields } from "./style"
import { Checkbox, Field, FormCol, HelpText, StepWrapper } from "../Section"
import StepWhiteBar from "../Icons/StepWhiteBar"

import { CustomValidationMessage, RequiredTriangle } from "../FormStyle"

const FField = styled.div`
  position: relative;
`
const CustomRequired = styled(CustomValidationMessage)`
  top: 100px;
  left: -10px;
`

class Step4 extends React.Component {
  render() {
    if (this.props.currentStep !== 4) {
      // Prop: The current step
      return null
    }

    return (
      <div>
        <FormFields>
          <FField>
            <label>Order Detail</label>
            <textarea
              name="order_detail"
              placeholder="Must be more than 500 charactors"
              id="order_detail"
              rows="6"
              onChange={this.props.handleChange}
            />
            <CustomRequired
              display={this.props.orderDetailRequire ? "block" : "none"}
            >
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </FField>
        </FormFields>
        <FormCol style={{ marginTop: 20 }}>
          <Field>
            <Checkbox>
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  id="agreeViaSms"
                  name="agreeViaSms"
                  defaultChecked={true}
                  onChange={this.props.handleChange}
                  aria-label="contacted via SMS"
                />
                {` `}I agree that I will be contacted via SMS.
              </label>
            </Checkbox>
            <HelpText>
              By submitting this form, I agree to receive text messages from
              Probuilt Steel Buildings. Standard messaging rates and data
              charges may apply. Message frequency may vary. You can opt-out by
              replying STOP at any time or reply Help to get more information.
            </HelpText>
          </Field>
        </FormCol>
        <StepWrapper top="250px" color="#676B79" left="20px">
          <div>
            <h3 style={{ color: "#ED602B" }}>1</h3>
            <div style={{ color: "#ED602B" }}>STEP</div>
            <StepWhiteBar fill="#ED602B" />
          </div>
          <div>
            <h3 style={{ color: "#ED602B" }}>2</h3>
            <div style={{ color: "#ED602B" }}>STEP</div>
            <StepWhiteBar fill="#ED602B" />
          </div>
          <div>
            <h3 style={{ color: "#ED602B" }}>3</h3>
            <div style={{ color: "#ED602B" }}>STEP</div>
            <StepWhiteBar fill="#ED602B" />
          </div>
          <div>
            <h3 style={{ color: "#ED602B" }}>4</h3>
            <div style={{ color: "#ED602B" }}>STEP</div>
          </div>
        </StepWrapper>
      </div>
    )
  }
}

export default Step4
